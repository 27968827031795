<template>
	<div class="pc-part">
		<topHeader></topHeader>
		<!-- 这个好像没有用 -->
		<!-- <div class="ac_slogan">
			<div class="ac_slogan_title">MetaEra 会务</div>
		</div> -->
		<div class="ac_banner">
			<div class="ac_slogan_subtitle">去中心化的链接， 全球化的视野。</div>
			<div class="ac_banner_content">
				<el-carousel :interval="0">
					<el-carousel-item v-for="(item, index) in bannerArray" :key="index">
						<div
							class="ac_banner_wrapper pointer"
							@click="showBannerDetail(item)"
						>
							<img :src="item.pic" :alt="item.alt" class="ac_banner_image" />
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
			<!--  金刚区 -->
			<div class="ac_banner_jgq padding_120 flex justify_content_sb">
				<div class="left-img-box img-box">
					<a target="_blank" href="https://www.metaera.hk/CryptoFi"
						><img
							src="https://res.metaera.hk/resources/assets/images/event/ac_top_banner_left_1.png"
					/></a>
				</div>
				<div class="right-img-box img-box">
					<a target="_blank" href="https://metaera.hk/summit2023/">
						<img
							src="https://res.metaera.hk/resources/assets/images/event/ac_top_banner_right_1.png"
						/>
					</a>
				</div>
			</div>
		</div>
		<!-- 活动合集 -->
		<div
			class="activities-box ac_calendar_aclist_bg padding_120"
			v-if="mainList.length > 0"
		>
			<activities :mainList="mainList"></activities>
		</div>
		<div class="main-box ac_calendar_aclist_bg padding_120 flex">
			<div class="main-left">
				<div class="flex justify_content_sb align_items_center">
					<p class="left-title">Events Exploer</p>
					<p class="clear pointer" @click="clearFilter">清除筛选</p>
				</div>

				<!-- 筛选条件 -->
				<select_filter
					@changeFilter="changeFilter"
					ref="selFilter"
				></select_filter>
				<div class="ac_calendar_aclist">
					<div
						class="ac_calendar_aclist_empty"
						v-if="activityArray.length == 0"
					>
						筛选后无任何活动，请重新筛选
					</div>
					<div class="">
						<acTips
							:item="item"
							v-if="showTips"
							@close="tipClose()"
							@showDetail="showACDetail()"
						>
						</acTips>
						<div class="ac_calendar_aclist_content">
							<div
								class="perItemBox"
								v-for="(item, index) in activityArray"
								:key="index"
							>
								<activePerItem :item="item"></activePerItem>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="main-right">
				<p class="left-title">Events News</p>
				<div class="ac_calendar_article">
					<div class="ac_calendar_article_list">
						<div v-for="(items, indexs) in articleList" :key="indexs">
							<wzitemnew
								class="wzitemnew"
								:class="{ firstitem: indexs == 0 }"
								:item="items"
							>
							</wzitemnew>
						</div>
						<div
							class="more flex align_items_c justify_content_c"
							@click="getDataListPage"
						>
							<span>展开更多</span>
							<img
								src="https://res.metaera.hk/resources/assets/images/active/4_1.png"
							/>
						</div>
					</div>
				</div>
				<download></download>
			</div>
		</div>
		<!--  以下是原始代码 -->
		<div class="dots" v-if="getNumberOfGroups().length > 1">
			<div
				class="dot"
				v-for="(item, index) in getNumberOfGroups()"
				:key="index"
				:class="{ active: index === currentIndex }"
				@mouseover="hoverDot(index)"
			>
			</div>
		</div>
		<!-- <bottom></bottom> -->
		<!-- <scrollToBottom @reachBottom="getDataListPage"></scrollToBottom> -->
	</div>
</template>

<script>
import {
	getArticleList,
	acitvityLists,
	acitvityHome,
	bannerInfo,
} from "@/api/home";
import topHeader from "../../components/pc/topHeader2.vue";
import bottom from "../../components/pc/bottom.vue";
import download from "../../components/pc/download.vue";
import activities from "../../components/activities.vue";
import wzitemnew from "./../../components/pc/wzitemnew.vue";
import perNews from "./../home/mobile/perNews.vue";
import acTips from "./ac_tips.vue";
import activePerItem from "./active_per_item.vue";
import select_filter from "./select_filter.vue";

export default {
	name: "",
	components: {
		topHeader,
		wzitemnew,
		bottom,
		acTips,
		perNews,
		activities,
		activePerItem,
		select_filter,
		download,
	},
	data() {
		return {
			osafai: false,
			olead: false,
			showTips: false,
			page: 1,
			size: 25,
			item: {},
			loading: false,
			articleList: [],
			selDate: new Date(),
			bannerArray: [],
			calendarRecommondArray: [],
			dates: [],
			activityArray: [],
			calendarActivity: [],
			mainList: [],
			currentIndex: 0,
			startTime: "开始日期",
			endTime: "结束日期",
			categoryValue: "全部",
			locationValue: "全部",
		};
	},
	created() {
		this.getAcitvityHome();
		this.getBannerInfo();
	},
	computed: {
		customFormat() {
			return (date) => {
				if (!date) return "";
				const options = { year: "numeric", month: "long" };
				return new Date(date).toLocaleDateString("zh-CN", options);
			};
		},
	},
	mounted() {
		this.preloadImage(
			"https://res.metaera.hk/resources/assets/images/active/13.png"
		);
	},
	methods: {
		getBannerInfo() {
			bannerInfo({ type: 3 })
				.then((res) => {
					this.bannerArray = res.data.list;
					console.log("首页获取banner数据", res);
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		clearFilter() {
			// 这里是清楚筛选
			this.$refs.selFilter.resetVal();
			// this.getAcitvityHome();
		},
		changeFilter(item) {
			console.log("这里是返回的筛选条件", item);
			this.startTime = item.startDate;
			this.endTime = item.endDate;
			this.categoryValue = item.categoryValue;
			this.locationValue = item.locationValue;
			this.getAcitvityHome();
		},
		preloadImage(src) {
			const link = document.createElement("link");
			link.rel = "preload";
			link.as = "image";
			link.href = src;
			document.head.appendChild(link);
		},
		handleClick() {
			// 手动触发日期选择器的弹出
			this.$refs.datePicker.pickerVisible = true;
		},
		handleChange(value) {
			this.selDate = value;
			this.getAcitvityHome();
			console.log("Selected date:", value);
		},
		getNumberOfGroups() {
			const groupSize = 4;
			const numberOfGroups = Math.ceil(
				this.calendarActivity.length / groupSize
			);
			return new Array(numberOfGroups).fill(null);
		},
		getCurrentCalendar() {
			const start = this.currentIndex * 4;
			const end = start + 4;
			return this.calendarActivity.slice(start, end);
		},
		hoverDot(index) {
			this.currentIndex = index;
		},
		showBannerDetail(item) {
			// 在新标签页中打开目标路由
			window.open(item.url, "_blank");
		},
		tipClose() {
			this.showTips = false;
		},
		tipShow(item) {
			this.item = item;
			// this.showTips = true;
			// 生成目标路由的 URL
			const routePath = this.$router.resolve({
				name: "/ac_detail",
				params: { id: this.item.id },
			}).href;
			// 在新标签页中打开目标路由
			window.open(routePath, "_blank");
		},
		showACDetail() {
			// 生成目标路由的 URL
			const routePath = this.$router.resolve({
				name: "/ac_detail",
				params: { id: this.item.id },
			}).href;
			// 在新标签页中打开目标路由
			window.open(routePath, "_blank");
		},
		showUrl(url) {
			window.open(url, "");
		},
		getDateClass(date, selDate) {
			if (
				date.getMonth() === selDate.getMonth() &&
				date.getDate() === selDate.getDate()
			) {
				return "selDay-date";
			} else if (date.getMonth() !== selDate.getMonth()) {
				return "noMonth-date";
			} else {
				let has = 0;
				for (let index = 0; index < this.dates.length; index++) {
					const element = this.dates[index];
					let tmpDate = new Date(element.day);
					if (date.getDate() == tmpDate.getDate()) {
						has = tmpDate.total_activities;
					}
				}
				if (has == 0) {
					return "noData-date";
				} else {
					return "numDay-date";
				}
			}
		},

		getDateNum(date, selDate) {
			if (date.getMonth() !== selDate.getMonth()) {
				return "0 ";
			} else {
				let has = "0 ";
				for (let index = 0; index < this.dates.length; index++) {
					const element = this.dates[index];
					let tmpDate = new Date(element.day);
					if (date.getDate() == tmpDate.getDate()) {
						has = element.total_activities;
					}
				}
				return has;
			}
		},
		handleDateClick(date) {
			console.log(date);
			console.log(this.selDate);
			this.currentIndex = 0;
			let has = 0;
			for (let index = 0; index < this.dates.length; index++) {
				const element = this.dates[index];
				let tmpDate = new Date(element.day);
				if (date.getDate() == tmpDate.getDate()) {
					console.log(tmpDate.getDate());
					this.calendarActivity = element.lists;
					has = element.total_activities;
				}
			}
			if (has == 0) {
				this.calendarActivity = this.calendarRecommondArray;
			}
			console.log(this.calendarActivity);

			if (this.calendarActivity.length == 0) {
				this.calendarActivity = this.bannerArray;
			}
		},

		getToday() {
			this.selDate = new Date();
			this.getAcitvityHome();
		},
		changeDate(index) {
			const year = this.selDate.getFullYear();
			const month = this.selDate.getMonth() + index;
			this.selDate = new Date(year, month, this.selDate.getDate());
			this.getAcitvityHome();
		},
		getYearMonth(date) {
			const year = this.selDate.getFullYear();
			const month = this.selDate.getMonth();
			const monthNames = [
				"一月",
				"二月",
				"三月",
				"四月",
				"五月",
				"六月",
				"七月",
				"八月",
				"九月",
				"十月",
				"十一月",
				"十二月",
			];
			return year + " " + monthNames[month];
		},
		getArticleList(obj) {
			let res = JSON.parse(sessionStorage.getItem("getArticleList3Data"));
			if (res != null && res.code == 200) {
				this.selarticleList = res.data.list.splice(0, 5);
			}
			getArticleList(obj)
				.then((res) => {
					if (res.code == 200) {
						this.loading = false;
						if (obj.page == 1) {
							this.articleList = res.data.list;
							sessionStorage.setItem(
								"getArticleList3Data",
								JSON.stringify(res)
							);
						} else {
							this.articleList = this.articleList.concat(res.data.list);
						}
						this.total = res.data.total;
					} else {
						this.$message({ message: res.message, type: "error" });
					}
				})
				.catch((error) => {
					this.$message({ message: error.message, type: "error" });
					return;
				});
		},
		getAcitvityHome() {
			let obj = {
				year: this.selDate.getFullYear(),
				month: this.selDate.getMonth() + 1,
				otherEvent: 1,
			};
			if (this.startTime != "开始日期" && this.endTime != "结束日期") {
				obj = {
					startTime: this.startTime,
					endTime: this.endTime,
					otherEvent: 1,
				};
			}

			if (this.categoryValue != "全部") {
				obj.categoryValue = this.categoryValue;
			}
			if (this.locationValue != "全部") {
				obj.locationValue = this.locationValue;
			}

			acitvityHome(obj)
				.then((res) => {
					if (res.code == 200) {
						// this.bannerArray = res.data.activityList;
						this.calendarRecommondArray = res.data.calendarRecommondList;
						this.dates = res.data.dates;
						this.mainList = res.data.mainAllList;
						let has = 0;
						for (let index = 0; index < this.dates.length; index++) {
							const element = this.dates[index];
							let tmpDate = new Date(element.day);
							if (this.selDate.getDate() == tmpDate.getDate()) {
								this.activityArray = element.lists;
								has = element.total_activities;
							}
						}
						if (has == 0) {
							this.activityArray = this.bannerArray;
						}
						this.activityArray = res.data.lists;
						this.handleDateClick(this.selDate);
					} else {
						this.$message({ message: res.message, type: "error" });
					}
					this.getArticleList({
						category_id: 0,
						type: 7,
						page: this.page,
						size: this.size,
					});
				})
				.catch((error) => {
					this.$message({ message: error.message, type: "error" });
					this.getArticleList({
						category_id: 0,
						type: 7,
						page: this.page,
						size: this.size,
					});
					return;
				});
		},
		getDataListPage() {
			if (this.loading) {
				return;
			}
			if (this.page >= Math.ceil(this.total / this.size)) {
				return;
			}
			this.loading = true;
			this.page = this.page + 1;
			this.getArticleList({
				category_id: 0,
				type: 7,
				page: this.page,
				size: this.size,
			});
		},
	},
};
</script>
<style>
.firstitem.wzitemnew {
	border-top: 0;
}
.el-select-dropdown {
	border: none;
	background-color: #212121 !important;
}
.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
	background-color: rgba(216, 216, 216, 0.2) !important;
}
.el-select-dropdown__item {
	margin: 0 10px;
	color: #ffffff !important;
}
.el-popper[x-placement^="bottom"] .popper__arrow {
	border-bottom-color: transparent;
}
.el-popper .popper__arrow::after {
	border: none;
}
</style>
<style scoped>
.more span {
	color: #fff;
}
.more img {
	width: clamp(10px, calc(100vw * 18 / 1440), 18px);
	height: clamp(10px, calc(100vw * 18 / 1440), 18px);
	transform: rotate(90deg);
}
.ac_banner_jgq {
	margin-top: clamp(20px, calc(100vw * 30 / 1440), 30px);
}
.ac_banner_jgq .img-box {
	width: clamp(500px, calc(100vw * 580 / 1440), 580px);
	height: clamp(160px, calc(100vw * 160 / 1440), 160px);
	border-radius: 6px;
	overflow: hidden;
}
.ac_banner_jgq img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.pd_body {
	height: 100vh;
	overflow-y: scroll;
}
.scroll-container {
	height: 100vh;
	overflow-y: scroll;
}
.js-copy {
	position: absolute;
	top: 0px;
	left: 0;
	width: calc(100vw);
	margin: 0 auto;
	background: black;
}
</style>

<!-- slogan -->
<style scoped>
.ac_slogan {
	width: 100%;
	margin-top: 100px;
}

.ac_slogan_title {
	display: none;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 103px;
	font-weight: 600;
	line-height: 121px;
	text-align: center;
	align-items: center;
	/* text-transform: uppercase; */
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	color: #ffffff;
}

.main-left {
	width: 65%;
	padding-right: 35px;
	border-right: 1px solid rgba(216, 216, 216, 0.4);
}

.left-title {
	font-size: 24px;
	font-weight: bold;
	line-height: 24px;
	/* 纯白 */
	color: #ffffff;
}
.clear {
	font-size: 18px;
	font-weight: bold;
	line-height: 1;
	/* 纯白 */
	color: #ffffff;
}
.main-right {
	width: 35%;
	padding-left: 35px;
}
</style>

<!-- banner -->
<style scoped>
.ac_banner {
	padding-top: clamp(20px, calc(100vw * 30 / 1440), 30px);
	padding-bottom: clamp(20px, calc(100vw * 50 / 1440), 50px);
	width: 100%;
	background: url(https://res.metaera.hk/resources/assets/images/jianbian.png);
	background-size: cover;
	background-position: 0 0;
	overflow: hidden;
	position: relative; /* 为了让伪元素相对于这个容器定位 */
}
.ac_banner::before {
	content: "";
	position: absolute;
	z-index: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 1) 0%,
		rgba(0, 0, 0, 0) 20%,
		rgba(0, 0, 0, 0) 80%,
		rgba(0, 0, 0, 1) 100%
	);
	pointer-events: none; /* 让伪元素不影响子元素的交互 */
}

.ac_slogan_subtitle {
	display: none;
	margin-top: 0px;
	margin-bottom: 80px;
	font-family: Inter;
	font-size: 24px;
	font-weight: normal;
	line-height: 31.2px;
	text-align: center;
	align-items: center;
	letter-spacing: 0em;
	position: relative;
	z-index: 1;
	color: rgba(255, 255, 255, 0.7);
}
.ac_banner_wrapper {
	position: relative;
	overflow: hidden;
	border-radius: 20px;
	width: 100%;
	height: 100%;
}

.ac_banner_image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover; /* 确保图片在容器中完整显示 */
	display: block;
	border-radius: 20px;
}
</style>

<!-- ac_calendar_wrapper -->
<style scoped>
.ac_calendar_wrapper {
	display: flex;
}

.ac_calendar_right {
	width: 40%;
}

.ac_recommend {
	display: flex;
	flex-wrap: wrap;
}

.dots {
	margin-left: 56%;
	margin-right: 9%;
	display: flex;
	justify-content: center;
	margin-top: 10px;
}

.dot {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #ccc;
	margin: 0 5px;
	cursor: pointer;
}

.dot.active {
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
}
.ac_recommend_list {
	flex: 1 0 43%;
	box-sizing: border-box;
	flex-grow: 0;
	position: relative;
	overflow: hidden;
	margin-bottom: 20px;
	max-height: 400px;
}
</style>
<!-- calendar -->
<style scoped>
.activities-box {
	border-top: 1px solid rgba(216, 216, 216, 0.2);
}
.ac_calendar {
	border-radius: 20px;
	margin-left: 10%;
	width: 44%;
	margin-right: 2%;
	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0.4) 0%,
		rgba(255, 255, 255, 0.2) 30%,
		rgba(255, 255, 255, 0.05) 100%
	);
	height: 650px;
}

.ac_calendar_header {
	width: 100%;
	margin-left: 0%;
	height: 130px;
}
.ac_calendar_header_top {
	width: 100%;
	height: 61px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.ac_calendar_header_top_left {
	margin-left: 30px;
}

.ac_calendar_header_top_left,
.ac_calendar_header_top_right {
	width: 20px;
	height: 20px;
	border-radius: 2px;
	margin-top: -8px;
	margin-right: 20px;
}

.ac_calendar_header_top_left img,
.ac_calendar_header_top_right img {
	width: 20px;
	height: 20px;
	border-radius: 2px;
}

.ac_calendar_header_top_month {
	display: none !important;
}

.ac_calendar_header_top_month,
.ac_calendar_date .el-input__inner {
	margin-left: 20px;
	margin-right: 20px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 26px;
	font-weight: normal;
	line-height: 24px;
	display: flex;
	align-items: center;
	text-transform: capitalize;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}

.ac_calendar_header_date {
	display: flex;
	align-items: center;
	margin-left: 20px;
}

.ac_calendar_header_today {
	margin-right: 20px;
	width: 80px;
	height: 30px;
	border-radius: 6px;

	/* 333 */
	background: rgba(51, 51, 51, 0.5);

	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 14px;
	align-items: center;
	align-content: center;
	letter-spacing: 0em;
	text-align: center;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}

.ac_calendar_header_week {
	margin-left: 0%;
	padding-right: 20px;
	padding-left: 20px;
	margin-top: 25px;
	display: flex;
	justify-content: space-around;
}

.ac_calendar_header_week_item {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 12px;
	font-weight: normal;
	line-height: 20px;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	text-align: center;
	/* 纯白 */
	color: #ffffff;
}

.ac_calendar_content {
	width: 100%;
	margin-left: 0%;
}
.ac_calendar .el-calendar {
	border: 0px solid #ddd;
	border-radius: 8px;
	/* margin-top: 30px; */
	background-color: rgba(255, 255, 255, 0);
}

.ac_calendar .el-calendar__header {
	display: none;
}

.ac_calendar .el-calendar__body {
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 0px;
	padding-bottom: 40px;
}

.ac_calendar .el-calendar-table {
	width: 100%;
	border-collapse: collapse;
}

.ac_calendar .el-calendar-table thead {
	display: none;
}
.ac_calendar .el-calendar-table td {
	border: 0px solid #ddd;
	text-align: center;
}

.ac_calendar .el-calendar-table .el-calendar-day {
	padding: 0px;
	height: 80px;
}

.ac_calendar .el-calendar-table tr td:first-child {
	border-left: 0;
}

.ac_calendar .el-calendar-table tr:first-child td {
	border-top: 0;
}
.ac_calendar .el-calendar-table td.today {
	background-color: #42b983;
	color: #fff;
}

.ac_calendar .el-calendar-table td.disabled {
	color: #ccc;
}
.custom-date-cell {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.custom-content {
	margin-top: 5px;
	font-size: 12px;
	color: #666;
}

.ac_calendar_date {
	width: 100%;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(12px, calc(100vw * 14 / 1280), 14px);
	font-weight: normal;
	line-height: 24px;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	text-align: center;
	color: #ffffff;
}
.ac_calendar_task {
	margin-top: 10px;
}

.ac_calendar_task_num {
	margin-right: 4px;
}
.ac_calendar_task,
.ac_calendar_task span {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(6px, calc(100vw * 10 / 1280), 14px);

	font-weight: normal;
	line-height: 16px;
	align-items: center;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}

.ac_calendar .noData-date {
	height: 100%;
	padding-top: 18px;
	padding-bottom: 18px;
	background-color: rgba(255, 255, 255, 0);
	border-radius: 10px;
	opacity: 1;
	box-sizing: border-box;
}
.ac_calendar .noData-date .ac_calendar_date {
	color: #aaaaaa;
}

.ac_calendar .noData-date .ac_calendar_task {
	color: #aaaaaa;
}

.ac_calendar .noMonth-date {
	height: 100%;
	padding-top: 18px;
	padding-bottom: 18px;
	border-radius: 10px;
	box-sizing: border-box;
}

.ac_calendar .noMonth-date .ac_calendar_date {
	color: #ffffff;
}

.ac_calendar .noMonth-date .ac_calendar_task {
	color: #ffffff;
}

.el-calendar-table td.is-selected,
.el-calendar-table .el-calendar-day:hover {
	background-color: rgba(255, 255, 255, 0);
}
.ac_calendar .selDay-date {
	height: 100%;
	padding-top: 18px;
	padding-bottom: 18px;
	border-radius: 10px;
	opacity: 1;
	/* 纯白 */
	background: #494949;
	box-sizing: border-box;
	/* 纯白 */
}

.ac_calendar .numDay-date {
	height: 100%;

	padding-top: 18px;
	padding-bottom: 18px;
	border-radius: 10px;
	box-sizing: border-box;
}

.ac_calendar .numDay-date .ac_calendar_date {
	color: #ffffff;
}

.ac_calendar .numDay-date .ac_calendar_task {
	color: #000000;
}
</style>

<!-- ac_calendar_aclist -->
<style scoped>
.ac_calendar_aclist {
	width: 100%;
	/* margin-top: 90px; */
	/* background: url(https://res.metaera.hk/resources/assets/images/active/12.png); */
	background-size: cover;
	background-position: center;
	overflow: hidden;
}

.ac_calendar_aclist_empty {
	margin-top: 40px;
	display: flex;
	width: 100%;
	justify-content: center;
	color: white;
}
.ac_calendar_aclist_bg {
	/* margin: 0 calc(100vw * 1 / 12); */
	margin-top: 35px;
}

.ac_calendar_aclist_title {
	font-family: "Bison-Bold";
	font-size: 60px;
	line-height: 60px;
	font-weight: 400;
	letter-spacing: 0em;
	text-align: center;
	align-content: center;
	color: #ffffff;

	/* font-variation-settings: "opsz" auto;


	text-decoration: underline;
	text-decoration-color: #0256ff;
	text-decoration-thickness: 4px;
	text-underline-offset: 10px; */
}

.ac_calendar_aclist_title1 {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 28px;
	font-weight: 400;
	line-height: 28px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: black;
	text-decoration: underline;
	text-decoration-color: #0256ff; /* 设置下划线颜色 */
	text-decoration-thickness: 4px; /* 设置下划线高度 */
	text-underline-offset: 10px; /* 调整下划线与文字的距离 */
}
.ac_calendar_aclist_date {
	margin-top: 40px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 24px;
	font-weight: 400;
	line-height: 24px;
	display: flex;
	align-items: center;
	text-transform: capitalize;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}
.ac_calendar_aclist_content {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 60px;
}

.perItemBox {
	width: 47%;
	border-bottom: 1px solid rgba(216, 216, 216, 0.2);
}

.ac_calendar_aclist_item_recommend {
	margin-top: 0px;
	flex: 1 0 40%;
	height: 100%;
}
</style>

<!-- ac_calendar_article -->
<style scoped>
.ac_calendar_article {
	width: 100%;
	padding-bottom: 80px;
}

.ac_calendar_article_header {
	margin-left: 10%;
	margin-right: 10%;
	padding-top: 60px;
	display: flex;
}
.ac_calendar_article_header_1 {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 28px;
	font-weight: 400;
	line-height: 28px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	color: #333333;
}
.ac_calendar_article_header_2 {
	margin-left: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 28px;
	font-weight: 400;
	line-height: 28px;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
	border-radius: 4px;
	opacity: 1;

	/* 主要色/主要色 */
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
}
.ac_calendar_article_list {
}
.wzitemnew {
	display: flex;
}
.perNews {
	display: none;
}
</style>
<style>
.ac_banner_content .el-carousel__container {
	width: clamp(800px, calc(100vw * (1200 / 1440)), 1200px);
	aspect-ratio: 3450/1400;
	height: auto;
	margin: 0 auto;
	background: white;
	border-radius: 20px;
}
</style>
